<template>
  <div class="p-col-12">
    <div class="card">
      <form name="taskForm" class="p-col-12" @submit.prevent="submitTaskForm">
        <div class="p-fluid p-formgrid p-grid">
          <div
            class="p-field p-col-12 p-md-6"
            v-if="
              task.action == 'create' ||
              (task.action == 'update' &&
                task.isBooking == true &&
                task.type == 1)
            "
          >
            <SelectButton
              v-model="task.taskType"
              :options="selectButtonOptions()"
              optionLabel="name"
              optionValue="value"
              @input="typeFormChanged"
            />
          </div>
          <div
            v-if="task.action == 'update'"
            class="p-field p-col-12 p-md-12"
            style="margin-top: 10px"
          >
            <span class="p-float-label">
              <Dropdown
                v-model="task.usersSelecteds"
                name="user"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-error': errors.has('user') },
                  'form-control',
                ]"
                :inputId="'user'"
                :options="myUsers"
                :optionLabel="getUserSearchLabel"
                :optionValue="'id'"
                :dataKey="'id'"
                :filter="true"
                :required="true"
              >
                <template #option="slotProps">
                  {{ slotProps.option.username }} ({{
                    slotProps.option.office
                  }})
                </template>
              </Dropdown>
              <label for="inputtext">Funcionário</label>
            </span>
          </div>

          <div
            v-if="task.action == 'view'"
            class="p-field p-col-12 p-md-12"
            style="margin-top: 10px"
          >
            <span class="p-float-label">
              <InputText
                type="text"
                v-model="task.technician"
                :disabled="true"
              />
              <label for="inputtext">Funcionário</label>
            </span>
          </div>

          <div
            v-if="task.action == 'create'"
            class="p-field p-col-12 p-md-12"
            style="margin-top: 10px"
          >
            <span class="p-float-label">
              <MultiSelect
                name="usersSelecteds"
                :options="myUsers"
                :filter="true"
                :optionLabel="getUserSearchLabel"
                v-model="task.usersSelecteds"
                v-validate="'required'"
                display="chip"
              >
                <template #option="slotProps">
                  {{ slotProps.option.username }} ({{
                    slotProps.option.office
                  }})
                </template>
              </MultiSelect>
              <label for="inputtext">Selecionar Funcionário</label>
            </span>
            <small
              v-if="errors.has('usersSelecteds')"
              class="p-error"
              role="alert"
              >Funcionário é obrigatório</small
            >
          </div>

          <div class="p-field p-col-12 p-md-4" style="margin-top: 10px">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-calendar"></i>
              </span>
              <span class="p-float-label">
                <v-date-picker
                  :disabled="task.action == 'view'"
                  name="day"
                  id="day"
                  v-model="task.day"
                  v-validate="'required'"
                  :min-date="new Date()"
                  :masks="{
                    input: 'YYYY-MM-DD',
                  }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <span class="p-float-label">
                      <input
                        :disabled="task.action == 'view'"
                        v-bind:class="[
                          {
                            'p-error': errors.has('day'),
                          },
                          'form-control',
                          'p-inputtext',
                          'p-component',
                          'p-filled',
                        ]"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                      <label for="inputtext">Dia</label>
                    </span>
                  </template>
                </v-date-picker>
              </span>
              <small v-if="errors.has('day')" class="p-error" role="alert"
                >Dia é obrigatório</small
              >
            </div>
          </div>

          <div class="p-field p-col-12 p-md-4" style="margin-top: 10px">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-clock"></i>
              </span>
              <span class="p-float-label">
                <Calendar
                  :disabled="task.action == 'view'"
                  id="start"
                  ref="start"
                  v-model="task.start"
                  v-validate="'required|date_format:H:mm'"
                  v-bind:class="[
                    { 'p-error': errors.has('start') },
                    'form-control',
                  ]"
                  :timeOnly="true"
                  :stepMinute="15"
                  name="start"
                />
                <label for="inputtext">Hora de início</label>
              </span>
            </div>
            <small v-if="errors.has('start')" class="p-error" role="alert"
              >Hora de início é obrigatório</small
            >
          </div>

          <div class="p-field p-col-12 p-md-4" style="margin-top: 10px">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-clock"></i>
              </span>
              <span class="p-float-label">
                <Calendar
                  :disabled="task.action == 'view'"
                  id="end"
                  v-model="task.end"
                  v-validate="'required|date_format:H:mm|after_start'"
                  v-bind:class="[
                    { 'p-error': errors.has('end') },
                    'form-control',
                  ]"
                  :timeOnly="true"
                  :stepMinute="15"
                  name="end"
                />
                <label for="inputtext">Hora de fim</label>
              </span>
            </div>
            <small v-if="errors.has('end')" class="p-error" role="alert"
              >Hora de fim tem que ser posterior à de início</small
            >
          </div>

          <div
            v-if="
              (task.action == 'create' && !task.isBooking) ||
              (task.action == 'update' &&
                !task.isBooking &&
                task.bookingId != undefined)
            "
            class="p-field p-col-12 p-md-12"
            style="margin-top: 10px"
          >
            <span class="p-float-label">
              <Dropdown
                ref="searchInput"
                :class="'form-control'"
                id="search"
                :inputId="'searchInput'"
                :options="searchItems"
                :optionLabel="getSearchLabel"
                :optionValue="'patNumber'"
                optionDisabled="disabled"
                :filter="true"
                name="searchInput"
                @input="searchSelected"
              >
                <template #option="slotProps">
                  <div
                    v-if="$isMobile()"
                    class="options-search-responsive p-ml-2"
                  >
                    <b v-if="slotProps.option.typename != 'Redmine'"> PAT: </b>
                    <b v-else> Issue: </b>
                    {{ slotProps.option.patNumber }}
                    | {{ slotProps.option.typename }}
                    <br />
                    <span v-if="slotProps.option.patTitle !== ''">
                      <b>Título: </b> {{ slotProps.option.patTitle }}
                    </span>
                    <span v-if="slotProps.option.patDescription !== ''">
                      <b>Descrição: </b>
                      {{ slotProps.option.patDescription }}
                    </span>
                    <span v-if="slotProps.option.disabled" class="p-error">
                      <b>Instalação sem Data. Marque Através do Menu Suporte->Instalações</b>
                    </span>
                  </div>
                  <div v-else class="options-search">
                    {{ slotProps.option.typename }} | <b>Projeto: </b>
                    {{ slotProps.option.projectName }} |
                    <b v-if="slotProps.option.typename != 'Redmine'"> PAT: </b>
                    <b v-else> Issue: </b>
                    {{ slotProps.option.patNumber }}
                    <span v-if="slotProps.option.patTitle !== ''">
                      <br /><b>Título: </b> {{ slotProps.option.patTitle }}
                    </span>
                    <span v-if="slotProps.option.patDescription !== ''">
                      <br /><b>Descrição: </b>
                      {{ slotProps.option.patDescription }}
                    </span>
                    <span v-if="slotProps.option.disabled" class="p-error">
                      <br />
                      <b>Instalação sem Data. Marque Através do Menu Suporte->Instalações</b>
                    </span>
                  </div>
                </template>
              </Dropdown>
              <label for="inputtext">Pesquisar PAT/Issue</label>
            </span>
          </div>
          <div
            v-bind:class="['p-md-4', 'p-field', 'p-col-12']"
            style="margin-top: 10px"
            v-if="task.isBooking != true"
          >
            <span class="p-float-label">
              <Dropdown
                v-if="
                  (task.action == 'create' && !task.isBooking) ||
                  (task.action == 'update' &&
                    !task.isBooking &&
                    task.bookingId != undefined)
                "
                v-model="task.type"
                name="type"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-error': errors.has('type') },
                  'form-control',
                ]"
                :options="getOptions('typeId')"
                :optionLabel="'typename'"
                :optionValue="'typeId'"
                :dataKey="'typeId'"
                :filter="true"
                :required="true"
                @input="typeChanged"
                :showClear="true"
              />
              <InputText
                v-else
                type="text"
                v-model="task.task_all_info.pat_type_name"
                :disabled="true"
              />
              <label for="inputtext">Tipo de tarefa</label>
            </span>
            <small v-if="errors.has('type')" class="p-error" role="alert"
              >Tipo de tarefa é obrigatório</small
            >
          </div>

          <div
            class="p-field p-col-12 p-md-4"
            style="margin-top: 10px"
            v-if="task.isBooking != true"
          >
            <span class="p-float-label">
              <Dropdown
                v-if="
                  (task.action == 'create' && !task.isBooking) ||
                  (task.action == 'update' &&
                    !task.isBooking &&
                    task.bookingId != undefined)
                "
                v-model="projectSelected"
                v-validate="'required'"
                name="project"
                v-bind:class="[
                  { 'p-error': errors.has('project') },
                  'form-control',
                ]"
                :inputId="'project'"
                :options="getOptions('projectId')"
                :optionLabel="'projectName'"
                :optionValue="'projectId'"
                :dataKey="'projectId'"
                :filter="true"
                @input="projectChanged"
                :showClear="true"
              />
              <InputText
                v-else
                type="text"
                v-model="task.task_all_info.project_name"
                :disabled="true"
              />
              <label for="inputtext">Projeto</label>
            </span>
            <small v-if="errors.has('project')" class="p-error" role="alert"
              >Projeto é obrigatório</small
            >
          </div>

          <div
            class="p-field p-col-12 p-md-4"
            style="margin-top: 10px"
            v-if="task.isBooking != true"
          >
            <span class="p-float-label">
              <Dropdown
                v-if="
                  (task.action == 'create' && !task.isBooking) ||
                  (task.action == 'update' &&
                    !task.isBooking &&
                    task.bookingId != undefined)
                "
                v-model="task.patNumber"
                v-validate="'required'"
                name="pat"
                v-bind:class="[
                  { 'p-error': errors.has('pat') },
                  'form-control',
                ]"
                :inputId="'pat'"
                :options="getOptions('patNumber')"
                :optionLabel="getPatLabel"
                :optionValue="'patNumber'"
                :optionDisabled="'disabled'"
                :dataKey="'patNumber'"
                :filter="true"
                @input="patChanged"
                :showClear="true"
              >
                <template #option="slotProps">
                  <div class="p-dropdown-pat-option">
                    <div>
                      <b v-if="slotProps.option.typeId == 4">Issue: </b>
                      <b v-else>PAT: </b>
                      {{ slotProps.option.patNumber }}
                    </div>
                    <br v-if="$isMobile()" />
                    <div v-if="slotProps.option.patTitle !== ''">
                      <b>Título: </b>{{ slotProps.option.patTitle }}
                    </div>
                    <br v-if="$isMobile()" />
                    <div v-if="slotProps.option.patDescription !== ''">
                      <b>Descrição: </b>{{ slotProps.option.patDescription }}
                    </div>
                    <div v-if="slotProps.option.disabled" class="p-error">
                      <b>Instalação sem Data. Marque Através do Menu Suporte->Instalações</b>
                    </div>
                  </div>
                </template>
              </Dropdown>
              <InputText
                v-else
                type="text"
                v-model="task.patNumber"
                :disabled="true"
              />
              <label for="inputtext">{{
                task.type == 4 ? "Issue" : "PAT"
              }}</label>
            </span>
            <small v-if="errors.has('pat')" class="p-error" role="alert">
              {{ task.type == 4 ? "Issue" : "PAT" }} é obrigatório
            </small>
          </div>

          <div
            class="p-field p-col-12 p-md-12"
            style="margin-top: 10px"
            v-if="task.action != 'create'"
          >
            <span class="p-float-label">
              <Textarea
                id="note"
                name="note"
                :disabled="true"
                :autoResize="true"
                v-model="task.task_all_info.pat_problem"
                v-bind:class="['p-inputtextarea', 'p-inputtext', 'p-component']"
                rows="5"
              />
              <label for="inputtext">Problema</label>
            </span>
          </div>

          <div class="p-field p-col-12 p-md-12" style="margin-top: 10px">
            <span class="p-float-label">
              <Textarea
                id="note"
                name="note"
                :disabled="task.action == 'view'"
                :autoResize="true"
                v-model="task.note"
                v-bind:class="[
                  { 'p-error': errors.has('note') },
                  'p-inputtextarea',
                  'p-inputtext',
                  'p-component',
                ]"
                rows="5"
              />
              <label for="inputtext">Nota</label>
            </span>
          </div>

          <div class="p-field p-col-12 p-md-12" style="margin-top: 10px">
            <span class="p-float-label">
              <Textarea
                id="resume"
                name="resume"
                :disabled="task.action == 'view'"
                :autoResize="true"
                v-model="task.resume"
                v-bind:class="[
                  { 'p-error': errors.has('resume') },
                  'p-inputtextarea',
                  'p-inputtext',
                  'p-component',
                ]"
                rows="5"
              />
              <label for="inputtext">Trabalho efectuado</label>
            </span>
          </div>

          <div class="p-field p-col-12 p-md-12" style="margin-top: 10px">
            <span class="p-float-label">
              <Textarea
                id="description"
                v-model="task.description"
                :disabled="task.action == 'view'"
                :autoResize="true"
                v-bind:class="[
                  { 'p-error': errors.has('description') },
                  'p-inputtextarea',
                  'p-inputtext',
                  'p-component',
                ]"
                name="description"
                rows="5"
              />
              <label for="inputtext">Pendente para a próxima intervenção</label>
            </span>
          </div>
          <div
            class="p-field p-col-12 p-md-6"
            v-if="task.localization instanceof Object"
            style="color: #767f86"
          >
            <h4>Morada:</h4>
            <div class="p-field p-md-12">
              {{ task.localization.address }} <br />
              {{ task.localization.zip_code }} - {{ task.localization.local }}
            </div>
            <div class="p-field p-col-12 p-md-4">
              <Button
                label="Ver no mapa"
                class="p-button-raised p-button-text"
                icon="pi pi-map-marker"
                @click="goToAdrress"
              />
            </div>
          </div>
          <div
            class="p-field p-col-12 p-md-6"
            v-if="
              task.action != 'create' &&
              task.task_all_info.has_installation_sheet
            "
            style="color: #767f86"
          >
            <h4>Folha de Instalação</h4>

            <div class="p-field p-col-12 p-md-7">
              <Button
                label="Ir para a folha de instalação"
                class="p-button-raised p-button-text"
                icon="pi pi-book"
                @click="goToInstallationSheet(task.task_all_info.id)"
              />
            </div>
          </div>
          <div class="p-field p-col-12 p-md-3" v-if="task.patNumber">
            <Button
              label="Ver Informação do Pat"
              class="p-button-primary"
              icon="pi pi-eye"
              @click="viewPatInfo(task.patNumber)"
            />
          </div>
          <div
            class="p-field p-col-12 p-md-12 p-text-right"
            style="color: #91969a"
            v-if="task.action != 'create'"
          >
            <small>
              Criado por {{ task.created_by }} {{ task.created_at }} <br />
              Última alteração por {{ task.updated_by }} {{ task.updated_at }}
            </small>
          </div>

          <div class="p-grid p-jc-center p-col-12">
            <div v-if="task.action != 'view'" class="p-col-12 p-md-3 p-mt-2">
              <Button
                icon="pi pi-check"
                label="Guardar"
                class="p-button-success"
                @click="submitTaskForm()"
              />
            </div>
            <div class="p-col-12 p-md-3 p-mt-2">
              <Button
                icon="pi pi-times"
                label="Cancelar"
                @click="cancelTaskForm()"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import VeeValidate from "vee-validate";
export default {
  name: "SupportForm",
  props: ["task", "interventionTypes", "myUsers"],
  data() {
    return {
      loading: false,
      message: "",
      searchItems: [],
      today: new Date(),
      projectSelected: null,
    };
  },
  beforeMount() {
    this.searchItems = this.getSearchData();
    if (this.task.action === "view" || this.task.action === "update") {
      this.patChanged(this.task.patNumber);
    }
  },
  created() {
    VeeValidate.Validator.extend("after_start", {
      validate: () =>
        new Promise((resolve) => {
          let day = new Date();
          if (!(this.task.day instanceof Date)) {
            day = new Date(this.task.day);
          } else {
            day = this.task.day;
          }

          if (!(this.task.start instanceof Date)) {
            let newStart = new Date();
            newStart.setSeconds(0);
            newStart.setHours(this.task.start.split(":")[0]);
            newStart.setMinutes(this.task.start.split(":")[1]);
            this.task.start = newStart;
          }
          if (!(this.task.end instanceof Date)) {
            let newEnd = new Date();
            newEnd.setSeconds(0);
            newEnd.setHours(this.task.end.split(":")[0]);
            newEnd.setMinutes(this.task.end.split(":")[1]);
            this.task.end = newEnd;
          }

          this.task.start.setFullYear(
            day.getFullYear(),
            day.getMonth(),
            day.getDate()
          );
          this.task.end.setFullYear(
            day.getFullYear(),
            day.getMonth(),
            day.getDate()
          );
          resolve({
            valid: this.task.end > this.task.start,
          });
        }),
    });
  },
  methods: {
    submitTaskForm() {
      this.loading = true;

      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.$emit("submitTaskForm", this.task);
      });
    },
    cancelTaskForm() {
      this.$emit("cancelTaskForm");
    },
    typeChanged(select) {
      this.task.patNumber = null;
      this.projectSelected = null;
      this.task.type = select;
    },
    projectChanged(select) {
      this.task.patNumber = null;
      this.projectSelected = select;

      if (select == null) {
        return;
      }

      const equalProject = (opt) => {
        return opt.projectId == this.projectSelected;
      };

      let items = this.getOptions("projectId");
      let filter = items.filter(equalProject);
      this.task.type = filter[0].typeId;
    },
    patChanged(select) {
      this.task.patNumber = select;
      if (select == null) {
        return;
      }
      this.task.type = null;
      this.projectSelected = null;

      const equalPat = (opt) => {
        return opt.patNumber == this.task.patNumber;
      };

      let items = this.getOptions("patNumber");
      let filter = items.filter(equalPat);
      this.task.type = filter[0].typeId;
      this.projectSelected = filter[0].projectId;
    },
    getPatLabel(info) {
      let prefix = "PAT";
      if (info.typeId == 4) {
        prefix = "Issue";
      }
      return `${prefix} ${info.patNumber} - ${info.patDescription}`;
    },
    getSearchData() {
      let searchData = [];
      let id = 0;
      this.interventionTypes.forEach((type) => {
        type.projects.forEach((project) => {
          if (project !== undefined) {
            project.pats.forEach((pat) => {
              searchData.push({
                id: id++,
                typeId: type.id,
                typename: type.name,
                projectId: project.id,
                projectName: project.name,
                patNumber: pat.pat_number,
                patTitle: pat.pat_title,
                patDescription: pat.pat_description,
                disabled: pat.installation_date == "1900-01-01" ? true : false,
              });
            });
          }
        });
      });
      return searchData;
    },
    getSearchLabel(info) {
      return `${info.typename} ${info.projectName} ${info.patNumber} ${info.patTitle} ${info.patDescription}`;
    },
    getUserSearchLabel(info) {
      return `${info.username} (${info.office})`;
    },
    searchSelected(select) {
      this.patChanged(select);
    },
    getOptions(key) {
      let options = [];
      let allItems = this.searchItems;

      if (this.task.type != null) {
        const equalType = (opt) => {
          return opt.typeId == this.task.type;
        };
        allItems = allItems.filter(equalType);
      }

      if (this.projectSelected != null) {
        const equalProject = (opt) => {
          return opt.projectId == this.projectSelected;
        };
        allItems = allItems.filter(equalProject);
      }
      allItems.map((x) =>
        options.filter((a) => a[key] == x[key]).length > 0
          ? null
          : options.push(x)
      );
      return options;
    },
    goToAdrress() {
      window.open(
        `http://maps.google.com/?q=${this.task.localization.address}, ${this.task.localization.zip_code}, ${this.task.localization.local}`,
        "_blank"
      );
    },
    typeFormChanged(areBooking) {
      this.$validator.pause();
      this.$nextTick(() => {
        this.$validator.errors.clear();
        this.$validator.fields.items.forEach((field) => field.reset());
        this.$validator.fields.items.forEach((field) =>
          this.errors.remove(field)
        );
        this.$validator.resume();
      });
      if (areBooking > 0) {
        this.task.isBooking = true;
        this.task.type = areBooking;
        this.task.taskType = areBooking;
        return;
      }
      this.task.isBooking = false;
      this.task.type = null;
    },
    selectButtonOptions() {
      if (this.task.action == "create") {
        return [
          { name: "Tarefa", value: 0 },
          { name: "Reserva", value: 1 },
          { name: "Indisponíbilidade", value: 2 },
        ];
      } else if (
        this.task.action == "update" &&
        this.task.isBooking == true &&
        this.task.type == 1
      ) {
        return [
          { name: "Tarefa", value: 0 },
          { name: "Reserva", value: 1 },
        ];
      } else if (this.task.isBooking == true && this.task.type == 2) {
        return [{ name: "Indisponíbilidade", value: 2 }];
      }
    },
    goToInstallationSheet(installationId) {
      let route = this.$router.resolve({
        path: `/task-installation-sheet/${installationId}`,
      });
      window.open(route.href, "_blank");
    },
    viewPatInfo(pat) {
      let route = this.$router.resolve({
        path: `/pat-details/${pat}`,
      });
      window.open(route.href, "_blank");
    },
  },
};
</script>
<style>
.p-dropdown-items li.p-dropdown-item.p-disabled{
  background-color: rgba(255, 0, 0, 0.1) !important;
  cursor: not-allowed !important;
}
</style>